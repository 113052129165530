const style = (isSmall) => {
    return {
        headerTitle: {
            fontSize: isSmall ? 30 : 45,
            letterSpacing: 0,
            fontWeight: 600,
            fontFamily: 'Source Code Pro',
        },
        title: {
            fontSize: 25,
            letterSpacing: 0,
            fontWeight: 600,
            fontFamily: 'Source Code Pro',
            marginBottom: 15
        },
        description: {
            fontSize: 18,
            letterSpacing: 0,
            fontFamily: 'Source Code Pro'
        },
        descriptionLink: {
            fontSize: 18,
            letterSpacing: 0,
            fontFamily: 'Source Code Pro',
            fontWeight: 600,
            textDecoration: "underline"
        }
    }
};

export default { default: style(false), responsive: style }