import React from "react";
import { useNavigate, useMatch } from "react-router-dom";

import CTAButton from "./CTAButton";

function NavigationButton({ title, exact, to, onNavigated, link}) {
  const match = useMatch(to)
  let navigate = useNavigate();

  const onPress = () => {
    if (link) {
      window.open(link)
      if (onNavigated) { onNavigated() }
    }else{
      navigate(to)
      if (onNavigated) { onNavigated() }
    }
  }

  return (
    <CTAButton
      title = {title}
      onPress = {onPress}
      type = "small"
      isSelected = {match}
    />
  );
}

export default NavigationButton
