import React, { useEffect } from 'react';
import { Pressable, View, StyleSheet, Image } from "react-native";

import openSeaIcon from '../assets/opensea.png'

function InteractiveNFTViewer({ nft, hideOSIcon }) {
    const render = () => {
        return (
            <iframe id={nft.visual} width={"100%"} height={"100%"} src={nft.visual} style={{ borderStyle: "none", overflow: "hidden" }} scroll="no" />
        )
    }

    const renderCollectionButtons = () => {
        if (nft.openSeaURL && !hideOSIcon) {
            return (
                <Pressable
                    onPress={() => {
                        window.open(nft.openSeaURL)
                    }}>
                    <Image
                        style={styles.icon}
                        source={openSeaIcon}
                    />
                </Pressable>
            )
        }
    }

    return (
        <View style={styles.content}>
            {render()}
            <View style={styles.navigation}>
                {renderCollectionButtons()}
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    content: {
        overflow: "hidden",
        backgroundColor: "#f7f7f7",
        width: "100%",
        height: "100%"
    },
    navigation: {
        position: 'absolute',
        flexDirection: "row",
        left: 20,
        bottom: 20,
    },
    icon: {
        width: 20,
        height: 20
    },
});

export default InteractiveNFTViewer;
