import React from 'react';
import { View, Text, StyleSheet, Linking } from "react-native";

import AboutCollection from '../About/AboutCollection'
import SpacingView from '../../components/SpacingView';
import CollectionsVisualURLs from '../../utils/CollectionsVisualURLs'
import GlobalStyles from '../../styles/GlobalStyles';
import PageHeader from '../../components/PageHeader';

import collabsHeader from './../../assets/headers/collabsHeader.png'
import useResponsive from '../../hooks/useResponsive';

function Collaborations() {
  const [isSmall] = useResponsive()

  return (
    <View>
      <View style={styles.content}>
        <PageHeader headerImage={collabsHeader} title={"Collaborations"}/>
        <Text style={GlobalStyles.responsive(isSmall).description}>
          {"We are not just an NFT project, we work with artists and projects to build their interactive pieces. We have worked with many big names as well as underrated artists. Our goal is to push the boundaries in this space when it comes to interactivity and so far we have done amazing things.\n\n"}
        </Text>
        <Text style={GlobalStyles.responsive(isSmall).description}>
          {"If you are a project owner or an artist and looking for fun, creative interactivity for your project, DM "} 
          <Text style={GlobalStyles.responsive(isSmall).description} onPress={() => Linking.openURL('https://twitter.com/intartnft')}>@intartnft</Text> 
          <Text>{" Twitter and let’s have a chat!"}</Text>
        </Text>
      </View>
      <SpacingView/>
      <SpacingView/>
      <SpacingView/>
      <SpacingView/>
      <AboutCollection
        title = {"int art x fortune friends club"}
        description = {'We love FFC! FFC wanted to have our signature one button to pixel art interactivity and we built it for them! Baos now can be either HD or pixel art on same NFT. Simply click on the button on top left corner and your cute Baos turn into pixel Baos!'}
        nftsProvider = {() => CollectionsVisualURLs.getRandomFFCVisuals() }
        openSeaLink = {"https://opensea.io/collection/fortune-friends-club-official-nft"}
        autoPlay = {true}
        canDisable = {false}
      />
      <SpacingView/>
      <SpacingView/>
      <SpacingView/>
      <AboutCollection
        title = {"int art x uwucrew x Persona Lamps"}
        description = {"We love to work with projects and support them to build creative features. uwucrew is just like us. For this collaboration we worked with uwucrew and Persona Lamps to make their lamps interactive. We added a traffic light to top left corner, when you click on it, the piece turns from a lamp to a character."}
        nftsProvider = {() => CollectionsVisualURLs.getRandomPersonaLampVisuals() }
        openSeaLink = {"https://opensea.io/collection/persona-lamps?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Rarity&search[stringTraits][0][values][0]=Mythic"}
        canDisable = {false}
      />
      <SpacingView/>
      <SpacingView/>
      <SpacingView/>
      <AboutCollection
        title = {"the pixels inc x friends"}
        description = {'Making friends while working on an NFT project is one of the best experiences you can get. We had the opportunity to meet very talented founders and we had amazing discussions on almost everything including some collaborations.\n\nFor our background extension we collaborated with 9 collections and created 11 collab backgrounds for the pixels. Each of these backgrounds are free for those collab collection owners. If you don’t own that collection you can still purchase them with our token $INT and add them to your pixels.\n\nHere our our collab projects: uwucrew, Cool Cats, Nobody, Chain Runners, Little Lemon Friends, Toy Boogers, Fluffy Polar Bears, Kohi, Sappy Seals.'}
        nftsProvider = {() => CollectionsVisualURLs.getRandomPixelsAndFriendsVisuals() }
        openSeaLink = {"https://opensea.io/collection/the-pixels-inc?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Background%20Extension&search[stringTraits][0][values][0]=BG%20%2391&search[stringTraits][0][values][1]=BG%20%2390&search[stringTraits][0][values][2]=BG%20%2389&search[stringTraits][0][values][3]=BG%20%2388&search[stringTraits][0][values][4]=BG%20%2387&search[stringTraits][0][values][5]=BG%20%2386&search[stringTraits][0][values][6]=BG%20%2385&search[stringTraits][0][values][7]=BG%20%2384&search[stringTraits][0][values][8]=BG%20%2383&search[stringTraits][0][values][9]=BG%20%2382"}
        canDisable = {true}
      />
      <SpacingView/>
      <SpacingView/>
      <SpacingView/>
      <AboutCollection
        title = {"int art x letters"}
        description = {'Vinnie Hager is one of the good friends we had in this journey. His art is beyond creative already but together we wanted to push the boundaries. We created two games with his two pieces.\n\nFirst one is the memory game. When you start, the game flashes you some shapes and then they disappear. The aim of the game is to remember the flashing shapes in correct order. If you complete them the whole piece gets colorful.\n\nSecond one is the maze game. From any point on the edges try to draw a line without touching any shape and get out the maze. The aim is to make the whole shapes visible by repeating the path drawing.'}
        nftsProvider = {() => CollectionsVisualURLs.letters }
        openSeaLink = {"https://opensea.io/collection/int-art-factory?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Collection&search[stringTraits][0][values][0]=Letters"}
        canDisable = {true}
        hideShowRandom = {true}
      />
      <SpacingView/>
      <SpacingView/>
      <SpacingView/>
      <AboutCollection
        title = {"int art x little lemon friends"}
        description = {'We love Lemons! Little Lemon Friends wanted to have a cute feature to turn their lemons to pixel lemons. We collaborated with them to make it happen. We made their lemons interactive and added a button to the top left corner. Simply click on the button and your lemons turn into pixel lemons!'}
        nftsProvider = {() => CollectionsVisualURLs.getRandomLemonVisuals() }
        openSeaLink = {"https://opensea.io/collection/little-lemon-friends"}
        autoPlay = {true}
        canDisable = {false}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  content: {
    maxWidth: 1000
  },
  wrapper: {
    display: "grid"
  },
  exampleContainer: {
    marginTop: 10,
    flex: 1,
    flexShrink: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    width: "auto",
    minWidth: 50,
  },
  image: {
    width: 250,
    height: 250,
    borderWidth: 3,
    bborderColor: "0x000000"
  },
  title: {
    fontSize: 18,
    letterSpacing: 0,
    fontWeight: 600,
    fontFamily: 'Source Code Pro'
  },
  description: {
    fontSize: 16,
    letterSpacing: 0,
    fontFamily: 'Source Code Pro'
  },
});

export default Collaborations;
