import { StyleSheet, View, Text, Linking, Image } from 'react-native';
import { useNavigate } from "react-router-dom";

import GlobalStyles from "../styles/GlobalStyles";
import SpacingView from "./SpacingView";
import CTAButton from "./CTAButton";
import useResponsive from '../hooks/useResponsive';

function CollectionCard({title, image, link, descriptionContent}) {
    const [isSmall] = useResponsive()
    const navigate = useNavigate();

    return (
        <View>
            <View>
                <View style={styles.collabContainer}>
                    <Image style={responsiveStyle(isSmall).collabImage} source={{uri: image}}></Image>
                    <View style={styles.collabDetailsContainer}>
                        <Text style={GlobalStyles.default.title}>{title}</Text>
                        {descriptionContent()}
                        <SpacingView/>
                        <CTAButton
                            title={"view"}
                            onPress={() => {
                                if (link.internal) {
                                    navigate(link.internal)
                                }else{
                                    Linking.openURL(link.external)
                                }
                            }}
                            type={"mid"}
                            disabled={false}
                        />
                    </View>
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    collabContainer: {
        flex: 1,
        flexDirection: "row",
        gap: 20
    },
    collabDetailsContainer: {
        flex: 1,
        flexDirection: "column"
    },
    collabImage: {
        width: 150,
        height: 150
    }
});

const responsiveStyle = (isSmall) => {
    if (isSmall) {
        return StyleSheet.create({
            collabImage: {
                width: 120,
                height: 120
            }
        });
    } else {
        return StyleSheet.create({
            collabImage: {
                width: 150,
                height: 150
            }
        });
    }
}

export default CollectionCard;