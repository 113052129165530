import logo from './logo.svg';
import './App.css';
import { StyleSheet, View, Text } from 'react-native';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Home from './pages/Home/Home'
import NavigationBar from './components/NavigationBar';
import Collections from './pages/Collections/Collections';
import About from './pages/About/About';
import History from './pages/History/History';
import Collaborations from './pages/Collaborations/Collaborations';
import Drops from './pages/Drops/Drops';
import Landing from './pages/Landing/Landing';
import useResponsive from './hooks/useResponsive';
import HeaderDescription from './components/HeaderDescription';
import SpacingView from './components/SpacingView';
import AboutTheDudes from './pages/Collections/AboutTheDudes';
import AboutThePixelsInc from './pages/Collections/AboutThePixelsInc';

function App() {
  const [isSmall] = useResponsive()

  return (
    <View style={[responsiveStyle(isSmall).container, styles.container]}>
      <BrowserRouter>
        <View style={styles.internalContainer}>
          <View style={responsiveStyle(isSmall).navigationBarContainer}>
            <NavigationBar />
          </View>
          <SpacingView/>
          <View style={styles.headerDescriptionContainer}>
            <HeaderDescription/>
          </View>
          <Routes>
            <Route path="/" element={
              <View style={styles.contentContainer}><Landing /></View>
            } />
            <Route path="/collections/the-dudes" element={
              <View style={styles.contentContainer}><AboutTheDudes /></View>
            } />
            <Route path="/collections/the-pixels-inc" element={
              <View style={styles.contentContainer}><AboutThePixelsInc /></View>
            } />
          </Routes>
        </View>
      </BrowserRouter>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  internalContainer: {
    height: "100%",
  },
  navigationBarContainer: {
    paddingTop: 40,
  },
  homeContainer: {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: -1
  },
  historyContainer: {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: -1
  },
  contentContainer: {
    maxWidth: 1000,
    paddingVertical: 40,
    position: "relative",
    zIndex: -10,
  },
  headerDescriptionContainer: {
  maxWidth: 1000,
    zIndex: -10,
  }
});

const responsiveStyle = (isSmall) => {
  if (isSmall) {
    return StyleSheet.create({
      container: {
        paddingHorizontal: 20,
      },
      navigationBarContainer: {
        paddingTop: 20,
      }
    });
  }else{
    return StyleSheet.create({
      container: {
        paddingHorizontal: 40,
      },
      navigationBarContainer: {
        paddingTop: 40,
      }
    });
  }
}

export default App;
