import React, { useState, useRef, useLayoutEffect } from 'react';
import { StyleSheet, View, Text } from 'react-native';


function History() {
    const iframeRef = useRef();

    return (
        <View style={styles.frameContainer}>
            <iframe ref={iframeRef} title='History' src="https://int.art/history/history_app.html" width='100%' height='100%' frameBorder="0"></iframe>
        </View>
    );
}

const styles = StyleSheet.create({
    frameContainer: {
        width: "100%",
        height: "100%"
    }
});

export default History;
