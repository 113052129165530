import React, { useState, useLayoutEffect } from 'react';
import { StyleSheet, View, Text } from 'react-native';

import NFTFrame from './NFTFrame';

function Home() {
  const [isMedium, setIsMedium] = useState(true);
  const [isSmall, setIsSmall] = useState(true);
  const [isTiny, setIsTiny] = useState(true);

  useLayoutEffect(() => {
    const updateSize = () => {
      setIsMedium(window.innerWidth < 800)
      setIsSmall(window.innerWidth < 500)
      setIsTiny(window.innerWidth < 400)
    }
    updateSize()
    window.addEventListener('resize', updateSize);
    return () => {
      window.removeEventListener('resize', updateSize);
    }
  }, []);

  const renderNFTFrame = () => {
    if (isMedium) {
      return (
        <View style={styles.nftFrameContaineSmall}>
          <NFTFrame />
        </View>
      )
    } else {
      return (
        <View style={styles.nftFrameContainer}>
          <NFTFrame />
        </View>
      )
    }
  }

  const renderLogo = () => {
    if (isMedium) {
      return (
        <View style={styles.logoContainerSmall}>
          <Text style={headerTitleStyle(isMedium, isSmall, isTiny)}>{"int art"}</Text>
          <Text style={headerDescriptionStyle(isMedium, isSmall, isTiny)}>{"a platform building interactive arts and design in web3 space."}</Text>
        </View>
      )
    }else{
      return (
        <View style={styles.logoContainer}>
          <Text style={headerTitleStyle(isMedium, isSmall, isTiny)}>{"int art"}</Text>
          <Text style={headerDescriptionStyle(isMedium, isSmall, isTiny)}>{"a platform building interactive arts and design in web3 space."}</Text>
        </View>
      )
    }
  }

  const render = () => {
    if (isMedium) {
      return (
        <View style={styles.container}>
          <View style={styles.internalContainerSmall}>
            {renderLogo()}
            {renderNFTFrame()}
          </View>
        </View>
      );
    } else {
      return (
        <View style={styles.container}>
          <View style={styles.internalContainer}>
            {renderLogo()}
            {renderNFTFrame()}
          </View>
        </View>
      );
    }
  }
  return (
   render()
  );
}

const headerTitleStyle = (isMedium, isSmall, isTiny) => {
  let style = {
    color: "#000000",
    fontFamily: 'Source Code Pro',
    fontWeight: 900,
    fontSize: 80,
  }
  if (isMedium) {
    style.fontSize = 60
  }else if (isTiny) {
    style.fontSize = 45
  }else if (isSmall) {
    style.fontSize = 60
  }else {
    style.fontSize = 80
  }
  return style
}

const headerDescriptionStyle = (isMedium, isSmall, isTiny) => {
  let style = {
    color: "#000000",
    fontFamily: 'Source Code Pro',
    fontWeight: 400,
    fontSize: 20,
    maxWidth: 500
  }
  if (isMedium) {
    style.fontSize = 20
    style.paddingLeft = 12
  }else if (isTiny) {
    style.fontSize = 20
  }else if (isSmall) {
    style.fontSize = 20
  }else {
    style.fontSize = 20
    style.paddingLeft = 20
  }
  return style
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
  },
  internalContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  internalContainerSmall: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start"
  },
  logoContainer: {
    flex: 1,
    borderColor: "#000000",
    justifyContent: "center",
    height: "100%"
  },
  logoContainerSmall: {
    borderColor: "#000000",
    justifyContent: "center",
    width: "100%",
    paddingTop: 80
  },
  nftFrameContainer: {
    flex: 1,
    borderColor: "#000000",
    justifyContent: "center",
    height: "100%"
  },
  nftFrameContaineSmall: {
    flex: 1,
    borderColor: "#000000",
    justifyContent: "center",
    width: "100%"
  }
});

export default Home;
