import React from 'react';
import { View, Text, StyleSheet, Image, Linking } from "react-native";

import AboutCollection from '../About/AboutCollection'
import SpacingView from '../../components/SpacingView';
import GlobalStyles from '../../styles/GlobalStyles';
import CollectionsVisualURLs from '../../utils/CollectionsVisualURLs'
import PageHeader from '../../components/PageHeader';

import collectionsHeader from '../../assets/headers/collectionsHeader.png'
import pixelsIncBackgroundExtension from '../../assets/collections/pixelsIncBackgroundExtension.png'
import pixelsIncSummerExtension from '../../assets/collections/pixelsIncSummerExtension.png'
import useResponsive from '../../hooks/useResponsive';

function AboutTheDudes() {
  const [isSmall] = useResponsive()

  return (
    <View>
      <AboutCollection
        title={"the dudes"}
        nftsProvider={() => CollectionsVisualURLs.getRandomTheDudesVisuals()}
        openSeaLink={"https://opensea.io/collection/thedudes"}
        canDisable={true}
        descriptionContent={() => {
          return (
            <View>
              <SpacingView/>
              <Text style={GlobalStyles.responsive(isSmall).description}>
                {"the dudes is a fully code generated, the first interactive collectible characters on Ethereum. Its source code is "}
                <Text style={GlobalStyles.responsive(isSmall).descriptionLink} onPress={() => Linking.openURL('https://github.com/intartnft/the-on-chain-dudes')}>stored on-chain</Text>
                {". It's the genesis collection and each token is a ticket for future experiences. \n\nAll traits are assigned inside the dudes smart contract and each rarity score is defined on the chain. There are 21,600 unique dudes on chain but only 512 are available to be claimed."}
              </Text>
            </View>
          )
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  pixelsIncExtensionImage: {
    backgroundColor: "#ffffff",
    width: "100%",
    resizeMode: 'contain',
    flex: 1,
    aspectRatio: 3
  }
});

export default AboutTheDudes;
