import React, { useEffect } from 'react';
import { View, StyleSheet } from "react-native";


function FrameHeaderView({ source }) {
    const render = () => {
        if (source.endsWith(".html")) {
            return (
                <iframe width={"100%"} height={"100%"} src={source} style={{ borderStyle: "none", overflow: "hidden" }} scroll="no" />
            )
        }else{
            return (
                <img width={"100%"} height={"100%"} src={source} style={{ borderStyle: "none", overflow: "hidden" }} scroll="no" />
            )
        }
    }

    return (
        <View style={styles.content}>
            {render()}
        </View>
    );
}

const styles = StyleSheet.create({
    content: {
        overflow: "hidden",
        backgroundColor: "#f7f7f7",
        width: "100%",
        aspectRatio: 3
    }
});

export default FrameHeaderView;
