import React, { useLayoutEffect, useEffect, useState, useRef } from 'react';
import { StyleSheet, View, Text } from 'react-native';

import InteractiveNFTViewer from '../../components/InteractiveNFTViewer';
import SpacingView from '../../components/SpacingView';
import CollectionsVisualURLs from '../../utils/CollectionsVisualURLs'

let mouseX = 0
let mouseY = 0
let visaliserSize;
let visualiserX;
let visualiserY;
let containerSize = 0;
let containerSizeCheckTimer = 0;
let nftRefreshTimer = 0;

function NFTFrame() {
    const containerRef = useRef();
    const infoContainerRef = useRef();
    const iframeRef = useRef();

    const [currentNFT, setCurrentNFT] = useState();
    const [visualiserLayout, setVisualiserLayout] = useState({ left: 10, right: 10, width: 300, height: 300 })
    const [infoLayout, setInfoLayout] = useState()

    useEffect(() => {
        const getContainerSize = () => {
            const containerWidth = containerRef.current.offsetWidth
            const containerHeight = containerRef.current.offsetHeight
            let containerSize = 0
            if (containerWidth / containerHeight > 1) {
                containerSize = containerHeight
            } else {
                containerSize = containerWidth
            }
            return containerSize
        }

        const sizeChecker = () => {
            let currentContainerSize = getContainerSize()
            if (currentContainerSize != containerSize) {
                containerSize = currentContainerSize
                renderFrame()
            }
        }

        const renderFrame = () => {
            iframeRef.current.contentWindow.mouseX = mouseX
            iframeRef.current.contentWindow.mouseY = mouseY
            iframeRef.current.contentWindow.sceneWidth = window.innerWidth
            iframeRef.current.contentWindow.sceneHeight = window.innerHeight

            const containerWidth = containerRef.current.offsetWidth
            const containerHeight = containerRef.current.offsetHeight
            let containerSize = 0
            if (containerWidth / containerHeight > 1) {
                containerSize = containerHeight
            } else {
                containerSize = containerWidth
            }

            const offsetX = ((mouseX / window.innerWidth) - 0.5) * (containerSize * 0.02)
            const offsetY = ((mouseY / window.innerHeight) - 0.5) * (containerSize * 0.02)

            visaliserSize = Math.floor(containerSize * 0.56)
            visualiserX = Math.floor(containerSize * 0.318 + (containerWidth - containerSize) * 0.5 + offsetX)
            visualiserY = Math.floor(containerSize * 0.22 + (containerHeight - containerSize) * 0.5 + offsetY)

            setInfoLayout({
                left: visualiserX,
                top: Math.floor(visualiserY + visaliserSize * 1.09),
                width: visaliserSize,
            })
            setVisualiserLayout({
                left: visualiserX,
                top: visualiserY,
                width: visaliserSize,
                height: visaliserSize,
            })
        }

        const updateMousePosition = (event) => {
            mouseX = event.clientX
            mouseY = event.clientY
            renderFrame()
            refreshNFT()
        }

        const updateSize = () => {
            renderFrame()
        }

        const stopRefreshingNFT = () => {
            clearTimeout(nftRefreshTimer)
        }
        
        const refreshNFT = () => {
            stopRefreshingNFT()
            nftRefreshTimer = setTimeout(() => {
                const pickedNFT = CollectionsVisualURLs.getRandomNFTForHome()[0]
                setCurrentNFT(pickedNFT)
                refreshNFT()
            }, 5000);
        }

        window.addEventListener('resize', updateSize);
        window.addEventListener('mousemove', updateMousePosition);
        containerSizeCheckTimer = setTimeout(() => {
            sizeChecker()
        }, 1);

        const pickedNFT = CollectionsVisualURLs.getRandomNFTForHome()[0]
        setCurrentNFT(pickedNFT)
        refreshNFT()

        mouseX = window.innerWidth * 0.5
        mouseY = window.innerHeight * 0.5

        updateSize();
        renderFrame();

        return () => {
            window.removeEventListener('resize', updateSize);
            window.removeEventListener('mousemove', updateMousePosition);
            clearTimeout(containerSizeCheckTimer)
            clearTimeout(nftRefreshTimer)
        }
    }, []);

    const renderNFT = () => {
        if (currentNFT) {
            return (
                <InteractiveNFTViewer
                    nft={currentNFT}
                    hideOSIcon={true}
                />
            )
        }
    }

    const renderInfo = () => {
        if (currentNFT) {
            let renderCollab = () => { }
            if (currentNFT.collaboratedWith) {
                renderCollab = () => {
                    return (
                        <View>
                            <SpacingView />
                            <Text style={styles.infoText}>
                                <Text style={styles.infoDescription}>{"collaborated with "}</Text>
                                <Text style={styles.infoOwnerLink} onPress={() => {
                                    window.open(currentNFT.collabArtistURL)
                                }}>
                                    {currentNFT.collaboratedWith}
                                </Text>
                            </Text>
                        </View>
                    )
                }
            } else if (currentNFT.originalArtist) {
                renderCollab = () => {
                    return (
                        <View>
                            <SpacingView />
                            <Text style={styles.infoText}>
                                <Text style={styles.infoDescription}>{"original artist "}</Text>
                                <Text style={styles.infoOwnerLink} onPress={() => {
                                    window.open(currentNFT.collabArtistURL)
                                }}>
                                    {currentNFT.originalArtist}
                                </Text>
                            </Text>
                        </View>
                    )
                }
            }
            return (
                <View>
                    <Text style={styles.infoTitle} onPress={() => {
                        window.open(currentNFT.openSeaURL)
                    }}>
                        {currentNFT.title}
                    </Text>
                    <Text style={styles.infoText}>
                        <Text style={styles.infoDescription}>{"from "}</Text>
                        <Text style={styles.infoOwnerLink} onPress={() => {
                            window.open(currentNFT.collectionURL)
                        }}>
                            {currentNFT.collection}
                        </Text>
                    </Text>
                    {renderCollab()}
                </View>
            )
        }
    }
    return (
        <View ref={containerRef} style={styles.container}>
            <View style={styles.frameContainer}>
                <iframe ref={iframeRef} title='NFT Frame' src="./nft_frame.html" width='100%' height='100%' frameBorder="0" styles="pointer-events: none;"></iframe>
            </View>
            <View style={[styles.visualContainer, visualiserLayout]}>
                {renderNFT()}
            </View>
            <View ref={infoContainerRef} style={[styles.infoContainer, infoLayout]}>
                {renderInfo()}
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        width: "100%",
        height: "100%",
        backgroundColor: "#FFFFFF",
        position: "relative",
        zIndex: 0,
    },
    frameContainer: {
        width: "100%",
        height: "100%",
        pointerEvents: "none"
    },
    visualContainer: {
        backgroundColor: "#FFFFFF",
        position: "absolute",
        zIndex: 5
    },
    infoContainer: {
        position: "absolute",
        zIndex: 5,
        opacity: 1,
    },
    infoText: {
        textAlign: "right"
    },
    infoTitle: {
        fontSize: 18,
        fontFamily: 'Source Code Pro',
        fontWeight: 900,
        textAlign: "right",
        marginBottom: 5,
        textDecorationLine: "underline"
    },
    infoDescription: {
        fontFamily: 'Source Code Pro',
        fontWeight: 400,
        textAlign: "right"
    },
    infoOwnerLink: {
        fontFamily: 'Source Code Pro',
        fontWeight: 600,
        textAlign: "right",
        textDecorationLine: "underline"
    }
});

export default NFTFrame;
