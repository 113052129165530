import { StyleSheet, View, Text } from 'react-native';

import useResponsive from '../hooks/useResponsive';

function HeaderDescription() {
    const [isSmall] = useResponsive()

    return (
        <View>
            <Text style={responsiveStyle(isSmall).description}>
                {"one man studio building experiences to push the boundaries of programmable art on distributed decentralised networks."}
            </Text>
        </View>
    )
}

const responsiveStyle = (isSmall) => {
    if (isSmall) {
        return StyleSheet.create({
            description: {
                fontSize: 20,
                fontFamily: 'Source Code Pro',
            },
        });
    } else {
        return StyleSheet.create({
            description: {
                fontSize: 20,
                fontFamily: 'Source Code Pro',
            },
        });
    }
}

export default HeaderDescription;