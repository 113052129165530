import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { View, Text, StyleSheet } from "react-native";

import useResponsive from '../../hooks/useResponsive';
import CTAButton from '../../components/CTAButton';
import GlobalStyles from '../../styles/GlobalStyles';
import SpacingView from '../../components/SpacingView';
import InteractiveNFTViewer from '../../components/InteractiveNFTViewer'

function AboutCollection({ title, hideShowRandom, description, descriptionContent, nftsProvider, openSeaLink, autoPlay, canDisable }) {
    const containerRef = useRef();
    const [isVisible, setIsVisible] = useState(true);
    const [nfts, setNFTs] = useState([]);
    const [isSmall] = useResponsive()
    const [filteredNFTs, setFilteredNFTs] = useState([])

    const refreshNFTs = () => {
        setNFTs(nftsProvider())
    }

    const onScroll = () => {
        const viewportOffset = containerRef.current.getBoundingClientRect();

        if (viewportOffset.top < window.innerHeight + 10 && viewportOffset.bottom > -10) {
            setIsVisible(true)
        } else {
            setIsVisible(false)
        }
    }

    useEffect(() => {
        refreshNFTs()
        onScroll()
    }, [])

    useEffect(() => {
        if (isSmall) {
            setFilteredNFTs(nfts.slice(0, 2))
        } else {
            setFilteredNFTs(nfts.slice(0, 3))
        }
    }, [nfts, isSmall])

    useLayoutEffect(() => {
        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, []);

    function renderDescriptionContent() {
        if (descriptionContent) {
            return descriptionContent()
        } else {
            return (
                <Text style={GlobalStyles.responsive(isSmall).description}>
                    {description}
                </Text>
            )
        }
    }

    function renderShowRandomButton() {
        if (!hideShowRandom) {
            return (
                <CTAButton
                    onPress={() => {
                        refreshNFTs()
                    }}
                    title="show random"
                />
            )
        }
    }

    return (
        <View ref={containerRef} style={styles.container}>
            <Text style={GlobalStyles.responsive(isSmall).title}>
                {title}
            </Text>
            <View key={Date.now()} style={styles.exampleContainer}>
                {filteredNFTs.map((nft, index) => {
                    if (isVisible) {
                        return (
                            <View style={styles.exampleItemContainer} key={index} >
                                <InteractiveNFTViewer nft={nft} autoPlay={autoPlay} canDisable={canDisable} />
                            </View>
                        )
                    }
                })}
            </View>
            <SpacingView/>
            <View>
                <View style={styles.footerButtonsContainer}>
                    <CTAButton
                        onPress={() => {
                            window.open(openSeaLink)
                        }}
                        title="view on OpenSea"
                    />
                    <SpacingView />
                    {renderShowRandomButton()}
                </View>
            </View>
            <SpacingView />
            <SpacingView />
            <View style={styles.content}>
                {renderDescriptionContent()}
            </View>
            <SpacingView />
            <SpacingView />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'baseline'
    },
    footerButtonsContainer: {
        flex: 1,
        flexDirection: "row",
        gap: 10,
        flexWrap: "wrap"
    },
    exampleContainer: {
        marginTop: 10,
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        gap: 10,
    },
    exampleItemContainer: {
        backgroundColor: "#f7f7f7",
        width: "100%",
        resizeMode: 'contain',
        flex: 1,
        aspectRatio: "1.01",
        alignSelf: "flex-start"
    },
    exampleItemContainerLast: {
        backgroundColor: "#f7f7f7",
        width: "100%",
        resizeMode: 'contain',
        flex: 1,
        aspectRatio: "1.01",
        alignSelf: "flex-start"
    }
});

export default AboutCollection;
