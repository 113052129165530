function getRandomMetroVisuals() {
  let items = []
  for (let i = 0; i < 3; i++) {
    const id = 1 + Math.floor(Math.random() * 2048)
    items.push(
      {
        collection: "the metro",
        title: "the metro #" + id,
        visual: "https://api.int.art/collections/visual/0xca24e7d9e8a2ba3ada22383f5e2ad397b5677e25/" + i,
        openSeaURL: "https://opensea.io/assets/ethereum/0xca24e7d9e8a2ba3ada22383f5e2ad397b5677e25/" + i,
        collectionURL: "https://opensea.io/collection/the-metro-from-int-art"
      }
    )
  }
  return items
}

function getRandomtheDudesFactoryVisuals() {
  const cloned = theDudesFactoryURLs.map(i => i)
  let urls = []
  for (let i = 0; i < 3; i++) {
    let pickedId = Math.floor(Math.random() * cloned.length)
    urls.push(cloned[pickedId])
    cloned.splice(pickedId, 1)
  }
  return urls
}

function getRandomtheDudsVisuals() {
  const cloned = theDuds.map(i => i)
  let urls = []
  for (let i = 0; i < 3; i++) {
    let pickedId = Math.floor(Math.random() * cloned.length)
    urls.push(cloned[pickedId])
    cloned.splice(pickedId, 1)
  }
  return urls
}

function getRandomPersonaLampVisuals() {
  const cloned = personaLamps.map(i => i)
  let urls = []
  for (let i = 0; i < 3; i++) {
    let pickedId = Math.floor(Math.random() * cloned.length)
    urls.push(
      {
        visual: "https://uwulabs.mypinata.cloud/ipfs/QmfN2jh3UH5UBLfPjei17gnkXE67jzspQxSDJv8jxsR3SQ/" + cloned[pickedId] + ".html",
        openSeaURL: "https://opensea.io/assets/ethereum/0xa3b041ee6b56bccbc54a3048417d82fe67736f62/" + cloned[pickedId]
      } 
    )
    cloned.splice(pickedId, 1)
  }
  return urls
}

function getRandomLemonVisuals() {
  const cloned = personaLamps.map(i => i)
  let urls = []
  for (let i = 0; i < 3; i++) {
    let pickedId = Math.floor(Math.random() * cloned.length)
    urls.push(
      {
        visual: "https://littlelemonfriends.mypinata.cloud/ipfs/QmZjQq1R1mpNhRxP87ZdrJPC1ZhJpKRMAkpJEA9wzQUDoj/" + cloned[pickedId] + ".html",
        openSeaURL: "https://opensea.io/assets/ethereum/0x0b22fe0a2995c5389ac093400e52471dca8bb48a/" + cloned[pickedId]
      } 
    )
    cloned.splice(pickedId, 1)
  }
  return urls
}

function getRandomFFCVisuals() {
  const cloned = personaLamps.map(i => i)
  let urls = []
  for (let i = 0; i < 3; i++) {
    let pickedId = Math.floor(Math.random() * cloned.length)
    urls.push(
      {
        visual: "https://ipfs.fortunefriends.club/ipfs/QmXphQDpWrwTffGAatF2TSSd2aDGH8iFk6ThQrrgz1uW21/" + cloned[pickedId] + ".html",
        openSeaURL: "https://opensea.io/assets/ethereum/0x0eff88e66eb717f8f7e0701345f1cc119b487f86/" + cloned[pickedId]
      } 
    )
    cloned.splice(pickedId, 1)
  }
  return urls
}

function getRandomThePixelsIncVisuals() {
  let items = []
  for (let i = 0; i < 3; i++) {
    const id = Math.floor(Math.random() * 5062)
    items.push(
      {
        collection: "the pixels inc",
        title: "the pixels inc #" + id,
        visual: "https://api.int.art/collections/the-pixels-inc/app/" + id,
        openSeaURL: "https://opensea.io/assets/ethereum/0x7ff2a00ff543f913b76010a05b5446e36d403675/" + id,
        collectionURL: "https://opensea.io/collection/the-pixels-inc"
      } 
    )
  }
  return items
}

function getRandomTheDudesVisuals() {
  let items = []
  for (let i = 0; i < 3; i++) {
    const id = Math.floor(Math.random() * 512)
    items.push(
      {
        collection: "the dudes",
        title: "the dudes #" + id,
        visual: "https://api.int.art/collections/visual/0xb0cf7da8dc482997525be8488b9cad4f44315422/" + id,
        openSeaURL: "https://opensea.io/assets/ethereum/0xb0cf7da8dc482997525be8488b9cad4f44315422/" + id,
        collectionURL: "https://opensea.io/collection/thedudes"
      }
    )
  }
  return items
}

function getRandomPixelsAndFriendsVisuals() {
  const cloned = pixelsAndFriends.map(i => i)
  let urls = []
  for (let i = 0; i < 3; i++) {
    let pickedId = Math.floor(Math.random() * cloned.length)
    urls.push(cloned[pickedId])
    cloned.splice(pickedId, 1)
  }
  return urls
}

const theDudesFactoryURLs = [
  {
    title: "the dude",
    collection: "int art factory",
    visual: "https://thedudes.mypinata.cloud/ipfs/QmabrJBkWMx9CggD2avohPQe42zVdxAAvTUQ5f4UEHgg8G/firstDude.html",
    openSeaURL: "https://opensea.io/assets/ethereum/0x656027bdbcd9eabbb167803c5d232de1faa4899e/0",
    collectionURL: "https://opensea.io/collection/int-art-factory"
  },
  {
    title: "the spooky dude",
    collaboratedWith: "Edward (itsonlymeman.eth)",
    collection: "int art factory",
    collabArtistURL: "https://twitter.com/edwardtheartist",
    visual: "https://thedudes.mypinata.cloud/ipfs/QmRLpP3bVjVy6meMWbjG5d4Ypwi52WaW1TrpJasHvGDxA3",
    openSeaURL: "https://opensea.io/assets/ethereum/0x656027bdbcd9eabbb167803c5d232de1faa4899e/4",
    collectionURL: "https://opensea.io/collection/int-art-factory"
  },
  {
    title: "reality or eyes are loud",
    collaboratedWith: "sepid",
    collabArtistURL: "https://twitter.com/o_waysea",
    collection: "int art factory",
    visual: "https://thedudes.mypinata.cloud/ipfs/QmacPCS21kNpAoLEnWzmNgtDHQS5jHdBWM2h9MSdjB2qDF/sepi1.html",
    openSeaURL: "https://opensea.io/assets/ethereum/0x656027bdbcd9eabbb167803c5d232de1faa4899e/6",
    collectionURL: "https://opensea.io/collection/int-art-factory"
  },
  {
    title: "horse man",
    collaboratedWith: "sepid",
    collabArtistURL: "https://twitter.com/o_waysea",
    collection: "int art factory",
    visual: "https://thedudes.mypinata.cloud/ipfs/QmacPCS21kNpAoLEnWzmNgtDHQS5jHdBWM2h9MSdjB2qDF/sepi2.html",
    openSeaURL: "https://opensea.io/assets/ethereum/0x656027bdbcd9eabbb167803c5d232de1faa4899e/7",
    collectionURL: "https://opensea.io/collection/int-art-factory"
  },
  {
    title: "hovering man in the metaverse",
    collection: "int art factory",
    collaboratedWith: "sepid",
    collabArtistURL: "https://twitter.com/o_waysea",
    visual: "https://thedudes.mypinata.cloud/ipfs/QmacPCS21kNpAoLEnWzmNgtDHQS5jHdBWM2h9MSdjB2qDF/sepi3.html",
    openSeaURL: "https://opensea.io/assets/ethereum/0x656027bdbcd9eabbb167803c5d232de1faa4899e/8",
    collectionURL: "https://opensea.io/collection/int-art-factory"
  },
  {
    title: "the girl in the smokes",
    collection: "int art factory",
    collaboratedWith: "sepid",
    collabArtistURL: "https://twitter.com/o_waysea",
    visual: "https://thedudes.mypinata.cloud/ipfs/QmacPCS21kNpAoLEnWzmNgtDHQS5jHdBWM2h9MSdjB2qDF/sepi4.html",
    openSeaURL: "https://opensea.io/assets/ethereum/0x656027bdbcd9eabbb167803c5d232de1faa4899e/9",
    collectionURL: "https://opensea.io/collection/int-art-factory"
  },
  {
    title: "the little dude",
    collection: "int art factory",
    collaboratedWith: "Clon",
    collabArtistURL: "https://twitter.com/cloncast",
    visual: "https://thedudes.mypinata.cloud/ipfs/QmW7EeRpoguY8gyVZTuQDYeM9E5RnDiSW7rkdEiaftbTKJ/thelittledude.html",
    openSeaURL: "https://opensea.io/assets/ethereum/0x656027bdbcd9eabbb167803c5d232de1faa4899e/10",
    collectionURL: "https://opensea.io/collection/int-art-factory"
  },

  {
    title: "time is memory",
    collection: "int art factory",
    collaboratedWith: "Vinnie Hager",
    collabArtistURL: "https://twitter.com/VinnieHager",
    visual: "https://thedudes.mypinata.cloud/ipfs/QmbyCceuGSxF27khct1xFrciW3RGyi25LXPHu63K2WjLcf/variant1/",
    openSeaURL: "https://opensea.io/assets/ethereum/0x656027bdbcd9eabbb167803c5d232de1faa4899e/11",
    collectionURL: "https://opensea.io/collection/int-art-factory"
  },
  {
    title: "a balance of",
    collection: "int art factory",
    collaboratedWith: "Vinnie Hager",
    collabArtistURL: "https://twitter.com/VinnieHager",
    visual: "https://thedudes.mypinata.cloud/ipfs/QmbyCceuGSxF27khct1xFrciW3RGyi25LXPHu63K2WjLcf/variant2/",
    openSeaURL: "https://opensea.io/assets/ethereum/0x656027bdbcd9eabbb167803c5d232de1faa4899e/12",
    collectionURL: "https://opensea.io/collection/int-art-factory"
  },
  {
    title: "gn dude",
    collection: "int art factory",
    originalArtist: "Grant Riven Yunr",
    collabArtistURL: "https://twitter.com/GrantYun2",
    visual: "https://thedudes.mypinata.cloud/ipfs/QmcEt4YoJSPx6YTnCAbxnDiXYZvBM4bSCoSCGYZY4QQjXJ/gn-dude.html",
    openSeaURL: "https://opensea.io/assets/ethereum/0x656027bdbcd9eabbb167803c5d232de1faa4899e/13",
    collectionURL: "https://opensea.io/collection/int-art-factory"
  },
  {
    title: "the BLeU dude",
    collection: "int art factory",
    originalArtist: "tjo",
    collabArtistURL: "https://twitter.com/0xTjo",
    visual: "https://thedudes.mypinata.cloud/ipfs/QmaHQSNwefnujiDGXpdAChJTnnY3xbbFFiUz8KZ4NDfWXw/index.html",
    openSeaURL: "https://opensea.io/assets/ethereum/0x656027bdbcd9eabbb167803c5d232de1faa4899e/14",
    collectionURL: "https://opensea.io/collection/int-art-factory"
  },
]

const personaLamps = [
  "1003",
  "1004",
  "1005",
  "1014",
  "1020",
  "1029",
  "1030",
  "104",
  "1042",
  "1045",
  "106",
  "1061",
  "1068",
  "1070",
  "1071",
  "1073",
  "1080",
  "1084",
  "1089",
]

const letters = [
  {
    visual: "https://thedudes.mypinata.cloud/ipfs/QmbyCceuGSxF27khct1xFrciW3RGyi25LXPHu63K2WjLcf/variant1/",
    openSeaURL: "https://opensea.io/assets/ethereum/0x656027bdbcd9eabbb167803c5d232de1faa4899e/11"
  },
  {
    visual: "https://thedudes.mypinata.cloud/ipfs/QmbyCceuGSxF27khct1xFrciW3RGyi25LXPHu63K2WjLcf/variant2/",
    openSeaURL: "https://opensea.io/assets/ethereum/0x656027bdbcd9eabbb167803c5d232de1faa4899e/12"
  }
]

const pixelsAndFriends = [
  {visual: "https://api.int.art/collections/the-pixels-inc/app/dna/010500021006000000000000_0182"},
  {visual: "https://api.int.art/collections/the-pixels-inc/app/dna/000303000104040000120100_0183"},
  {visual: "https://api.int.art/collections/the-pixels-inc/app/dna/021401081003000000000000_0184"},
  {visual: "https://api.int.art/collections/the-pixels-inc/app/dna/000301000806000006100000_0185"},
  {visual: "https://api.int.art/collections/the-pixels-inc/app/dna/021504080601010000000000_0186"},
  {visual: "https://api.int.art/collections/the-pixels-inc/app/dna/001103070500000000000000_0187"},
  {visual: "https://api.int.art/collections/the-pixels-inc/app/dna/001103060908004000170200_0188"},
  {visual: "https://api.int.art/collections/the-pixels-inc/app/dna/100703050903001400160200_0189"},
  {visual: "https://api.int.art/collections/the-pixels-inc/app/dna/001501060605000012160000_0190"},
  {visual: "https://api.int.art/collections/the-pixels-inc/app/dna/101501070906000000060000_0191"},
  {visual: "https://api.int.art/collections/the-pixels-inc/app/dna/001404040206003400170500_0192"},
]

const theDuds = [
  {visual: "https://thedudes.mypinata.cloud/ipfs/QmV533Ck8Jc9LPK9EQPestS4k3XqEvgNci667seizY52Hx/407.html"},
  {visual: "https://thedudes.mypinata.cloud/ipfs/QmV533Ck8Jc9LPK9EQPestS4k3XqEvgNci667seizY52Hx/408.html"},
  {visual: "https://thedudes.mypinata.cloud/ipfs/QmV533Ck8Jc9LPK9EQPestS4k3XqEvgNci667seizY52Hx/409.html"},
  {visual: "https://thedudes.mypinata.cloud/ipfs/QmV533Ck8Jc9LPK9EQPestS4k3XqEvgNci667seizY52Hx/410.html"},
  {visual: "https://thedudes.mypinata.cloud/ipfs/QmV533Ck8Jc9LPK9EQPestS4k3XqEvgNci667seizY52Hx/411.html"},
  {visual: "https://thedudes.mypinata.cloud/ipfs/QmV533Ck8Jc9LPK9EQPestS4k3XqEvgNci667seizY52Hx/412.html"},
  {visual: "https://thedudes.mypinata.cloud/ipfs/QmV533Ck8Jc9LPK9EQPestS4k3XqEvgNci667seizY52Hx/413.html"},
  {visual: "https://thedudes.mypinata.cloud/ipfs/QmV533Ck8Jc9LPK9EQPestS4k3XqEvgNci667seizY52Hx/414.html"},
  {visual: "https://thedudes.mypinata.cloud/ipfs/QmV533Ck8Jc9LPK9EQPestS4k3XqEvgNci667seizY52Hx/415.html"},
  {visual: "https://thedudes.mypinata.cloud/ipfs/QmV533Ck8Jc9LPK9EQPestS4k3XqEvgNci667seizY52Hx/416.html"},
  {visual: "https://thedudes.mypinata.cloud/ipfs/QmV533Ck8Jc9LPK9EQPestS4k3XqEvgNci667seizY52Hx/417.html"},
]

const getRandomNFTForHome = () => {
  const index = Math.floor(Math.random() * 4)
  if (index == 0) {
    return getRandomTheDudesVisuals()
  }else if (index == 1) {
    return getRandomThePixelsIncVisuals()
  }else if (index == 2) {
    return getRandomtheDudesFactoryVisuals()
  }else if (index == 3) {
    return getRandomMetroVisuals()
  }
}

export default {
  getRandomtheDudesFactoryVisuals,
  getRandomThePixelsIncVisuals,
  getRandomTheDudesVisuals,
  getRandomPersonaLampVisuals,
  getRandomLemonVisuals,
  letters,
  getRandomPixelsAndFriendsVisuals,
  getRandomtheDudsVisuals,
  getRandomNFTForHome,
  getRandomFFCVisuals
};
