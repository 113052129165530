import React from 'react';
import { View, Text, StyleSheet, Image, Linking } from "react-native";

import AboutCollection from '../About/AboutCollection'
import SpacingView from '../../components/SpacingView';
import GlobalStyles from '../../styles/GlobalStyles';
import CollectionsVisualURLs from '../../utils/CollectionsVisualURLs'
import PageHeader from '../../components/PageHeader';

import collectionsHeader from '../../assets/headers/collectionsHeader.png'
import pixelsIncBackgroundExtension from '../../assets/collections/pixelsIncBackgroundExtension.png'
import pixelsIncSummerExtension from '../../assets/collections/pixelsIncSummerExtension.png'
import pixelsIncSocialExtension from '../../assets/collections/pixelsIncSocialExtension.png'
import pixelsIncChristmasExtension from '../../assets/collections/pixelsIncChristmasExtension.png'
import pixelsIncMetroExtension from '../../assets/collections/pixelsIncMetroExtension.png'

import useResponsive from '../../hooks/useResponsive';
import CTAButton from '../../components/CTAButton';

//https://app.int.art/the-pixels-inc-extensions
function AboutThePixelsInc() {
  const [isSmall] = useResponsive()

  return (
    <View>
      <AboutCollection
        title={"the pixels inc"}
        nftsProvider={() => CollectionsVisualURLs.getRandomThePixelsIncVisuals()}
        openSeaLink={"https://opensea.io/collection/the-pixels-inc"}
        autoPlay={false}
        canDisable={true}
        descriptionContent={() => {
          return (
            <View>
              <Text style={GlobalStyles.responsive(isSmall).description}>
                {"the first interactive and programmable/extendable collectible characters on Ethereum. Released in 2021, exhibited in Hong Kong and Tokyo."}
              </Text>
              <SpacingView />
              <Text style={GlobalStyles.responsive(isSmall).description}>
                {'Collection constantly evolves and provides new experiences as the the pixels incs are programmable collection.'}
              </Text>

              <SpacingView />
              <SpacingView />
              <SpacingView />

              <Text style={GlobalStyles.responsive(isSmall).title}>
                {"Extensions"}
              </Text>
              <Text style={GlobalStyles.responsive(isSmall).description}>
                {"Extensions allow owners to add new experiences like traits or backgrounds on your existing 'the pixels inc' without creating new NFTs, in other words it gives the owners the ability to customize their pixels. The extensions you claimed for your pixels are stored on-chain."}
              </Text>

              <SpacingView/>
              <CTAButton
                onPress={() => {
                  Linking.openURL("https://app.int.art/the-pixels-inc-extensions")
                }}
                title="extend your the pixel inc"
              />

              <SpacingView />
              <SpacingView />
              <SpacingView />

              <Text style={GlobalStyles.responsive(isSmall).title}>
                {"The Metro Extension"}
              </Text>
              <Image source={pixelsIncMetroExtension} style={styles.pixelsIncExtensionImage} />
              <SpacingView />
              <Text style={GlobalStyles.responsive(isSmall).description}>
                {"This extension is dedicated to our first int art drop series the metro collection. 'the metro' is collection that has 2048 evolving metro maps, slowly grow with time, just like how a small cities get bigger and bigger with new metro lines. It is on-chain, interactive, evolving in unpredictable way completely on/in-chain. Have your the metro token ready to extend your pixel with randomly picked one of the three metro themed backgrounds."}
              </Text>

              <SpacingView />
              <SpacingView />
              <SpacingView />

              <Text style={GlobalStyles.responsive(isSmall).title}>
                {"Social Extension"}
              </Text>
              <Image source={pixelsIncSocialExtension} style={styles.pixelsIncExtensionImage} />
              <SpacingView />
              <Text style={GlobalStyles.responsive(isSmall).description}>
                {"Our social extension is one of the most innovative extensions we have yet created. Now our pixels can send each other messages, read their own messages and keep the messages visible on their pixels. What’s even better is that all the messages are on-chain. Can we say this is the first on-chain social messaging in NFTs? We believe so."}
              </Text>

              <SpacingView />
              <SpacingView />
              <SpacingView />

              <Text style={GlobalStyles.responsive(isSmall).title}>
                {"Summer Extension 2022"}
              </Text>
              <Image source={pixelsIncSummerExtension} style={styles.pixelsIncExtensionImage} />
              <SpacingView />
              <Text style={GlobalStyles.responsive(isSmall).description}>
                {"Summer extension is created specifically for summer 2022. As we are proud to have a contract that enables us to add new traits and backgrounds to our existing pixels, with this summer extension we took our pixels to a vacation. The extension has 3 animated backgrounds and 2 new traits. Some combinations are more rare than the others."}
              </Text>

              <SpacingView />
              <SpacingView />
              <SpacingView />
              <Text style={GlobalStyles.responsive(isSmall).title}>
                {"Background Extension"}
              </Text>
              <Image source={pixelsIncBackgroundExtension} style={styles.pixelsIncExtensionImage} />
              <SpacingView />
              <Text style={GlobalStyles.responsive(isSmall).description}>
                {"Background extension is created with our community together. We created a community art contest and invited everyone to submit pixel backgrounds. In total 81 backgrounds were submitted and 5 winners were picked. The winners are rewarded from our DAO fund. All 81 community backgrounds are claimable by the pixels inc owners.\n\nShortly after 9 official collaboration project backgrounds were released and they represent some of the friends we have made along the way."}
              </Text>

              <SpacingView />
              <SpacingView />
              <SpacingView />
              <Text style={GlobalStyles.responsive(isSmall).title}>
                {"Christmas 2021 Extension"}
              </Text>
              <Image source={pixelsIncChristmasExtension} style={styles.pixelsIncExtensionImage} />
              <SpacingView />
              <Text style={GlobalStyles.responsive(isSmall).description}>
                {"We released Christmas 2021 extension in December 2021. It was the proof of concept for our extensions tech behind the pixels. There are 3 different Christmas traits with different rarity."}
              </Text>
              <SpacingView />
              <SpacingView />
              <Text style={GlobalStyles.responsive(isSmall).description}>
                {"Christmas 2021 extension claiming has now ended. It was released for free and variants were distributed randomly. Don't worry, there will be more extensions in near future!"}
              </Text>
            </View>
          )
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  pixelsIncExtensionImage: {
    backgroundColor: "#ffffff",
    width: "100%",
    resizeMode: 'fill',
    flex: 1,
    aspectRatio: 3
  }
});

export default AboutThePixelsInc;
