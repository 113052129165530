import React from 'react';
import { View, Text, StyleSheet } from "react-native";

import GlobalStyles from '../../styles/GlobalStyles';
import CTAButton from '../../components/CTAButton';
import PageHeader from '../../components/PageHeader';
import SpacingView from '../../components/SpacingView';
import FrameHeaderView from '../../components/FrameHeaderView';

import dropsHeader from './../../assets/headers/dropsHeader.png'
import theStalkerHeader from './../../assets/drops/theStalker.png'

import useResponsive from '../../hooks/useResponsive';

function Drops() {
    const [isSmall] = useResponsive()

    return (
        <View>
            <PageHeader headerImage={dropsHeader} title={"Drops"} />
            <Text style={GlobalStyles.responsive(isSmall).description}>
                {"Drops is the new series from int art. All the collections from drops are technically innovative, interactive and 100% on/in-chain. They all have a story to tell and created for art and creative coding lovers."}
                {"\n\nthe dudes and the pixels inc owners will always have early access opportunity to these drop collections."}
            </Text>
            <SpacingView />
            <SpacingView />
            <View style={styles.contentContainer}>
                <Text style={GlobalStyles.responsive(isSmall).title}>
                    {"the stalker"}
                </Text>
                <FrameHeaderView source={theStalkerHeader}></FrameHeaderView>
                <SpacingView />
                <SpacingView />
                <Text style={GlobalStyles.responsive(isSmall).description}>
                    {"the stalker is an art collaboration program running inside the EVM. It collaborates with on-chain CC0 projects directly on-chain."}
                    {"\n\nthe stalker's first collaboration is with FRESH HELL which is the first only on-chain collection from XCOPY released 	with Manifold."}
                    {"\n\nthe stalker gets the allowed collection tokens and creates new experiences on top of them. To achieve this, the stalker collaborates not just with artworks but with the programs that living inside the EVM. For example for the XCOPY's FRESH HELL collection, it communicates with Efficiax from @0xdiid to get the artwork and then communicates with scripty.sol to make it interactive."}
                </Text>
                <SpacingView />
                <View style={styles.ctaButton}>
                    <CTAButton
                        onPress={() => {
                            window.open("https://drops.int.art/the-stalker")
                        }}
                        title="view collection"
                    />
                </View>
            </View>
            <SpacingView />
            <SpacingView />
            <View style={styles.contentContainer}>
                <Text style={GlobalStyles.responsive(isSmall).title}>
                    {"the metro"}
                </Text>
                <FrameHeaderView source={"./metro_intart_header.html"}></FrameHeaderView>
                <SpacingView />
                <SpacingView />
                <Text style={GlobalStyles.responsive(isSmall).description}>
                    {"Isn't it interesting, when you are travelling in a new city, your eyes immediately look for a metro map?"}
                    {"\n\nSome city metro maps are complicated, connecting hundreds of stations and lines. Some are pretty minimal with only a few stops.. But they all have the same design language. It's universal. This collection is inspired from our travels to new cities and the metro maps encountered during our explorations. We wanted to create evolving metro maps that slowly grow with time, just like how a small cities get bigger and bigger with new metro lines."}
                    {"\n\nAnd this is how 'the metro' is born. It's on-chain, interactive, evolving in unpredictable way completely on/in-chain. This is the first collection from the 'drop' series from int.art. "}
                </Text>
                <SpacingView />
                <View style={styles.ctaButton}>
                    <CTAButton
                        onPress={() => {
                            window.open("https://drops.int.art/the-metro")
                        }}
                        title="view collection"
                    />
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    contentContainer: {
        alignItems: 'baseline' 
    },
    ctaButton: {
        // display: "inline-block",
        // flex: 1,
        // width: 200
    }
});

export default Drops;
