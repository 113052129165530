import React from 'react';
import { View, Text, StyleSheet, Image, Linking } from "react-native";

import AboutCollection from '../About/AboutCollection'
import SpacingView from '../../components/SpacingView';
import GlobalStyles from '../../styles/GlobalStyles';
import CollectionsVisualURLs from '../../utils/CollectionsVisualURLs'
import PageHeader from '../../components/PageHeader';

import collectionsHeader from '../../assets/headers/collectionsHeader.png'
import pixelsIncBackgroundExtension from '../../assets/collections/pixelsIncBackgroundExtension.png'
import pixelsIncSummerExtension from '../../assets/collections/pixelsIncSummerExtension.png'
import useResponsive from '../../hooks/useResponsive';

function Collections() {
  const [isSmall] = useResponsive()

  return (
    <View>
      <PageHeader headerImage={collectionsHeader} title={"Collections"} />
      <AboutCollection
        title={"the dudes"}
        nftsProvider={() => CollectionsVisualURLs.getRandomTheDudesVisuals()}
        openSeaLink={"https://opensea.io/collection/thedudes"}
        canDisable={true}
        descriptionContent={() => {
          return (
            <View>
              <Text style={GlobalStyles.responsive(isSmall).description}>
                {"the dudes is a fully code generated, "}
                <Text style={GlobalStyles.responsive(isSmall).descriptionLink} onPress={() => Linking.openURL('https://thedudes.art/playground/the-on-chain-dudes/#147232')}>on-chain stored</Text>
                {" NFT collection. It's the genesis collection of the project. No designer worked on this. No one actually drew these. It's just some code that generates random ugly dudes.\n\nAll traits are assigned inside the smart contract and each rarity score is defined on the chain. There are 21,600 unique dudes on chain but only 512 are available to be claimed."}
              </Text>
            </View>
          )
        }}
      />
      <SpacingView />
      <SpacingView />
      <SpacingView />
      <SpacingView />
      <SpacingView />
      <AboutCollection
        title={"the pixels inc"}
        nftsProvider={() => CollectionsVisualURLs.getRandomThePixelsIncVisuals()}
        openSeaLink={"https://opensea.io/collection/the-pixels-inc"}
        autoPlay={false}
        canDisable={true}
        descriptionContent={() => {
          return (
            <View>
              <Text style={GlobalStyles.responsive(isSmall).description}>
                {'"the pixels inc" is the first interactive pixelated PFP NFT. It\'s the second biggest drop after the dudes. Their traits are embedded into the smart contract and they are generated at mint.\n\nCollection will constantly be evolving as its contract can delegate other contracts to manipulate each pixel. Since NFT world is constantly changing and new occasions are emerging, this contract feature will enable "the pixels inc" to adopt different traits for every special occasion, such as halloween and holiday season.'}
              </Text>
            </View>
          )
        }}
      />
      <SpacingView />
      <SpacingView />
      <SpacingView />
      <SpacingView />
      <SpacingView />
      <AboutCollection
        title={"int art factory"}
        description={'A playground for collaborative and strictly interactive work with amazing NFT artists. This is a friendly space where big and underrated names come express their one of a kind unique pieces. Pieces are given away to collection owners for free by doing an on-chain raffle.'}
        nftsProvider={() => CollectionsVisualURLs.getRandomtheDudesFactoryVisuals()}
        openSeaLink={"https://opensea.io/collection/int-art-factory"}
        canDisable={false}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  pixelsIncExtensionImage: {
    backgroundColor: "#ffffff",
    width: "100%",
    resizeMode: 'contain',
    flex: 1,
    aspectRatio: 3
  }
});

export default Collections;
