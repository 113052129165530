import { useLayoutEffect, useState } from "react";

const useResponsive = () => {
    const [isSmall, setIsSmall] = useState()

    useLayoutEffect(() => {
        const updateSize = () => {
            setIsSmall(window.innerWidth < 650)
        }

        window.addEventListener('resize', updateSize);
        updateSize()

        return () => {
            window.removeEventListener('resize', updateSize);
        }
    }, []);

    return [isSmall]
};

export default useResponsive;