import React from 'react';
import { View, Text, StyleSheet } from "react-native";

import GlobalStyles from '../../styles/GlobalStyles';
import PageHeader from '../../components/PageHeader';
import SpacingView from '../../components/SpacingView';

import aboutHeader from './../../assets/headers/aboutHeader.png'
import useResponsive from '../../hooks/useResponsive';

function About() {
    const [isSmall] = useResponsive()
    
    return (
        <View>
            <PageHeader headerImage={aboutHeader} title={"About"} />
            <Text style={GlobalStyles.responsive(isSmall).description}>
                {"int art is the go-to platform for building interactive art and design on the blockchain. We believe the future of digital art and design is having immersive experiences by interacting with the pieces. "}    
            </Text>
            <SpacingView/>
            <SpacingView/>
            <Text style={GlobalStyles.responsive(isSmall).description}>
                {"We work with project owners, artists and creative coders from all domains to bring interactivity to their art. Whether it is photography, 2D/3D digital art or any other domain, with our expertise in creative coding we build NFTs with interactive experiences for art collectors."}    
            </Text>
            <SpacingView/>
            <SpacingView/>
            <Text style={GlobalStyles.responsive(isSmall).description}>
                {"From our platform we launched two first of its kind interactive NFT collections, which are generative, programmable and extendable. Always keeping development at the core, we provide our holders the great experience of customizing their NFTs. Our collections are community-driven and owner-centric. We grow our project with our collection owners. "}    
            </Text>
            <SpacingView/>
            <SpacingView/>
            <Text style={GlobalStyles.responsive(isSmall).description}>
                {"With the programmability of the art, possibilities are endless. We are here to build the future of interactive art & design with today’s most innovative artists and coders."}    
            </Text>
        </View>
    );
}

const styles = StyleSheet.create({});

export default About;
