import React from 'react';
import { View, Text, StyleSheet, Image } from "react-native";
import useResponsive from '../hooks/useResponsive';
import GlobalStyles from '../styles/GlobalStyles';

function PageHeader({title, headerImage}) {
    const [isSmall] = useResponsive()

    return (
        <View style={styles.container}>
            <Text style={GlobalStyles.responsive(isSmall).headerTitle}>
                {title}
            </Text>
            <Image source={headerImage} style={styles.headerImage}/>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        marginBottom: 20
    },
    title: {
      fontSize: 45,
      letterSpacing: 0,
      fontWeight: 600,
      fontFamily: 'Source Code Pro',
    },
    headerImage: {
        marginTop: 20,
        backgroundColor: "#f7f7f7",
        width: "100%",
        resizeMode: 'contain',
        flex: 1,
        aspectRatio: 3
    }
  });

export default PageHeader;
