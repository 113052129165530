import React from "react";
import { View, Pressable, Image, StyleSheet } from "react-native";

import SpacingView from './SpacingView'
import discordIcon from '../assets/discord.png'
import twitterIcon from '../assets/twitter.png'
import githubIcon from '../assets/github.png'

function SocialBarView({style}) {
    return (
        <View style={[styles.container, style]}>
            <Pressable
                onPress={() => {
                    window.open("https://twitter.com/intartnft")
                }}>
                <Image
                    style={styles.icon}
                    source={twitterIcon}
                />
            </Pressable>
            <SpacingView type="horizontal" />
            <Pressable
                onPress={() => {
                    window.open("https://discord.com/invite/4AbTQsFnf3")
                }}>
                <Image
                    style={styles.icon}
                    source={discordIcon}
                />
            </Pressable>
            <SpacingView type="horizontal" />
            <Pressable
                onPress={() => {
                    window.open("https://github.com/intartnft")
                }}>
                <Image
                    style={styles.icon}
                    source={githubIcon}
                />
            </Pressable>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "row"
    },
    icon: {
        width: 20,
        height: 20
    },
});

export default SocialBarView
