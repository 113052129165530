import React, { useState, useEffect } from 'react';
import { View, Pressable, Text, StyleSheet } from "react-native";
import { useNavigate } from "react-router-dom";

function NavigationLogoButton({ to, title, isSelected }) {
  const navigate = useNavigate();
  const [buttonBackgroundStyle, setButtonBackgroundStyle] = useState()
  const [buttonTextStyle, setButtonTextStyle] = useState()

  useEffect(() => {
    if (isSelected) {
      hoverIn()
    } else {
      hoverOut()
    }
  }, [isSelected])


  const handleEvent = (event) => {
    if (isSelected) { return }
    if (event == "onHoverIn") {
      hoverIn()
    } else {
      hoverOut()
    }
  }

  const hoverIn = () => {
    setButtonBackgroundStyle([styles.base, styles.hoverIn])
    setButtonTextStyle([styles.textBase, styles.textHoverIn])
  }

  const hoverOut = () => {
    setButtonBackgroundStyle([styles.base, styles.normal])
    setButtonTextStyle([styles.textBase, styles.textNormal])
  }

  return (
    <View>
      <Pressable
        style={buttonBackgroundStyle}
        onPress={ () => { navigate(to) }}
        onHoverIn={() => { handleEvent('onHoverIn') }}
        onHoverOut={() => { handleEvent('onHoverOut') }}
      >
        <Text style={buttonTextStyle}>
          {title}
        </Text>
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  base: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    elevation: 3,
    height: 40,
    width: "auto"
  },
  normal: {
    backgroundColor: '#FFFFFF',
  },
  hoverIn: {
    backgroundColor: '#FFFFFF',
  },
  textBase: {
    fontSize: 24,
    lineHeight: 21,
    letterSpacing: 0.25,
    color: 'black',
    fontWeight: 900,
    fontFamily: 'Source Code Pro'
  },
  textNormal: {
    color: 'black',
  },
  textHoverIn: {
    color: 'black',
    fontWeight: 500
  },
});

export default NavigationLogoButton
