import React from "react";
import { View, StyleSheet } from "react-native";

function SpacingView({type}) {
  return (
    <View style={type == "horizontal" ? styles.horizontal : styles.vertical}/>
  );
}

const styles = StyleSheet.create({
  horizontal: {
    width: "10px",
    color: 'white'
  },
  vertical: {
    height: "10px",
    color: 'white'
  }
});

export default SpacingView
