import React from 'react';
import {Pressable, StyleSheet, Image } from "react-native";

function IconButton({ onPress, icon}) {
    return (
        <Pressable
            onPress={() => {
                onPress()
            }}>
            <Image
                style={styles.icon}
                source={icon}
            />
        </Pressable>
    );
}

const styles = StyleSheet.create({
    icon: {
        backgroundColor: "#FF0000",
        width: 40,
        height: 40
    },
});

export default IconButton
