import { useEffect } from "react"
import { StyleSheet, View, Text, Linking, Image } from 'react-native';
import { useNavigate } from "react-router-dom";

import GlobalStyles from "../../styles/GlobalStyles";
import SpacingView from "../../components/SpacingView";
import CollectionCard from "../../components/CollectionCard";
import useResponsive from "../../hooks/useResponsive";

import img_theDudes from "../../assets/collections/the_dudes.png"
import img_thePixelsInc from "../../assets/collections/the_pixels_inc.png"
import img_scriptySol from "../../assets/collections/scripty.sol.png"
import img_theMetro from "../../assets/collections/the_metro.png"
import img_theStalker from "../../assets/collections/the_stalker.png"

function Landing() {
    const [isSmall] = useResponsive()
    const navigate = useNavigate();

    return (
        <View style={styles.content}>
            <CollectionCard
                title={"the dudes"}
                image={img_theDudes}
                link={{internal: "/collections/the-dudes"}}
                descriptionContent={() => {
                    return (
                        <View>
                            <Text style={GlobalStyles.responsive(isSmall).description}>
                                {"the first interactive collectible characters on Ethereum. Released in 2021, stored on Ethereum."}
                            </Text>
                        </View>
                    )
                }}
            />
            <SpacingView />
            <SpacingView />
            <SpacingView />
            <SpacingView />
            <CollectionCard
                title={"the pixels inc"}
                image={img_thePixelsInc}
                link={{internal: "/collections/the-pixels-inc"}}
                descriptionContent={() => {
                    return (
                        <View>
                            <Text style={GlobalStyles.responsive(isSmall).description}>
                                {"the first interactive and programmable collectible characters on Ethereum. Released in 2021, exhibited in Hong Kong and Tokyo."}
                            </Text>
                        </View>
                    )
                }}
            />
            <SpacingView />
            <SpacingView />
            <SpacingView />
            <SpacingView />
            <CollectionCard
                title={"scripty.sol"}
                image={img_scriptySol}
                link={{external: "https://github.com/intartnft/scripty.sol"}}
                descriptionContent={() => {
                    return (
                        <View>
                            <Text style={GlobalStyles.responsive(isSmall).description}>
                                {"the open source library for dynamic and modular HTML content creation on EVM. Released in 2023 and used by Alba, Art Blocks and many collections including CryptoCoasters, the metro and GOLD."}
                            </Text>
                        </View>
                    )
                }}
            />
            <SpacingView />
            <SpacingView />
            <SpacingView />
            <SpacingView />
            <CollectionCard
                title={"the metro"}
                image={img_theMetro}
                link={{external: "https://drops.int.art/the-metro"}}
                descriptionContent={() => {
                    return (
                        <View>
                            <Text style={GlobalStyles.responsive(isSmall).description}>
                                {"the artwork imagining the unpredictable formation of evolving metro networks on Ethereum. Released in 2023."}
                            </Text>
                        </View>
                    )
                }}
            />
            <SpacingView />
            <SpacingView />
            <SpacingView />
            <SpacingView />
            <CollectionCard
                title={"the stalker"}
                image={img_theStalker}
                link={{external: "https://drops.int.art/the-stalker"}}
                descriptionContent={() => {
                    return (
                        <View>
                            <Text style={GlobalStyles.responsive(isSmall).description}>
                                {"the artwork exploring composability between on-chain artworks on Ethereum. Collaborated with XCOPY and Gremplin. Released in 2023."}

                            </Text>
                        </View>
                    )
                }}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    content: {
        maxWidth: 1000
    },
});

export default Landing;