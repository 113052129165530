import React, { useState, useLayoutEffect, useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { useNavigate, useMatch } from "react-router-dom";

import useResponsive from '../hooks/useResponsive';
import NavigationButton from './NavigationButton';
import NavigationLogoButton from './NavigationLogoButton';
import SocialBarView from './SocialBarView';
import IconButton from './IconButton';

import menuIcon from './../assets/menu.png'

function NavigationBar() {
    const [isSmall] = useResponsive()
    const [isOverlayMenuVisible, setIsOverlayMenuVisible] = useState(false);

    useEffect(() => {
        if (!isSmall) {
            setIsOverlayMenuVisible(false)
        }
    }, [isSmall]);

    const renderMenuButtons = (containerStyle, socialBarStyle) => {
        return (
            <View style={containerStyle}>
                {/* <NavigationButton to="/about" onNavigated={() => { setIsOverlayMenuVisible(false) }} title="about" /> */}
                {/* <NavigationButton to="/collections" onNavigated={() => { setIsOverlayMenuVisible(false) }} title="collections" /> */}
                {/* <NavigationButton to="/drops" onNavigated={() => { setIsOverlayMenuVisible(false) }} title="drops" /> */}
                {/* <NavigationButton to="/history" onNavigated={() => { setIsOverlayMenuVisible(false) }} title="history" /> */}
                <NavigationButton to="/docs" onNavigated={() => { setIsOverlayMenuVisible(false) }} link="https://docs.int.art" title="docs" />
                <NavigationButton to="/member-access" onNavigated={() => { setIsOverlayMenuVisible(false) }} link="https://app.int.art" title="member access" />
                <View style={{ marginLeft: 5 }}>
                    <SocialBarView style={socialBarStyle} />
                </View>
            </View>
        )
    }

    const renderOverlayMenu = () => {
        if (isOverlayMenuVisible) {
            return (
                renderMenuButtons(styles.overlayMenuContainer, {paddingVertical: 10})
            )
        }
    }

    const handleMenuButtonAction = () => {
        setIsOverlayMenuVisible(!isOverlayMenuVisible)
    }

    const renderMenuItems = () => {
        if (!isSmall) {
            return (
                renderMenuButtons(styles.internalContainer)
            )
        } else {
            return (
                <View style={{ position: "relative" }}>
                    <IconButton icon={menuIcon} onPress={() => {
                        handleMenuButtonAction()
                    }} />
                    <View style={styles.container}>
                        {renderOverlayMenu()}
                    </View>
                </View>
            )
        }
    }

    const renderMenu = () => {
        if (isSmall) {
            return (
                <View style={styles.container}>
                    <View style={styles.internalContainer}>
                        {renderMenuItems()}
                        <NavigationLogoButton to="/" title="int art" />
                    </View>
                </View>
            )
        } else {
            return (
                <View style={styles.container}>
                    <View style={styles.internalContainer}>
                        <NavigationLogoButton to="/" title="int art" />
                        {renderMenuItems()}
                    </View>
                </View>
            )
        }
    }
    return (
        renderMenu()
    );
}

const styles = StyleSheet.create({
    container: {},
    internalContainer: {
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        gap: 20,
    },
    hamburgerButtonContainer: {
        backgroundColor: "#FFFF00",
        width: 40,
        height: 40
    },
    overlayMenuContainer: {
        backgroundColor: "#FFFFFF",
        position: "absolute",
        top: 10,
        left: 0,
        zIndex: 50000,
        gap: 10,
        paddingHorizontal: 10,
        paddingBottom: 10
    }
});

export default NavigationBar;
