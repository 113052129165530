import React, { useState, useEffect } from 'react';
import { View, Pressable, Text, StyleSheet } from "react-native";

function CTAButton({ onPress, title, disabled, isSelected }) {
  const [shouldHighlight, setShouldHighlight] = useState()

  useEffect(() => {
    setShouldHighlight(isSelected != undefined)
  }, [isSelected])

  const handleEvent = (event) => {
    if (event == "onHoverIn") {
      setShouldHighlight(true)
    } else {
      setShouldHighlight(false)
    }
  }

  const getButtonStyle = () => {
    if (isSelected || shouldHighlight) {
      return [styles.base, styles.hoverIn]
    }else{
      return [styles.base, styles.normal]
    }
  }

  const getTextStyle = () => {
    if (isSelected || shouldHighlight) {
      return [styles.textBase, styles.textHoverIn]
    }else{
      return [styles.textBase, styles.textNormal]
    }
  }

  return (
    <View style={styles.container}>
      <Pressable
        style={getButtonStyle()}
        onPress={disabled ? () => { } : onPress}
        onHoverIn={() => { handleEvent('onHoverIn') }}
        onHoverOut={() => { handleEvent('onHoverOut') }}
      >
        <Text style={getTextStyle()}>
          {title}
        </Text>
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'flex-start'
  },
  base: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 32,
    elevation: 3,
    height: 40,
    width: "auto"
  },
  normal: {
    backgroundColor: '#f7f7f7',
  },
  hoverIn: {
    backgroundColor: '#000000',
  },
  textBase: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 0.25,
    color: 'black',
    fontFamily: 'Source Code Pro'
  },
  textNormal: {
    color: 'black',
  },
  textHoverIn: {
    color: 'white',
  },
});

export default CTAButton
